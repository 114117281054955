<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    PageHeader,
  },
  data() {
    return {
      // Start Employee Records
      HrEmployees: [],
      HrDepartments: [],
      HrJobTitles: [],
      employee: {},
      allSelected: false,
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrEmployees: null,
      HrEmployeesSearchMode: false,
      searchModel: "",
      state: state,
      // End Employee Records

      // Start Empolyee attendance
      HrAttendance: [],
      attendancePage: 1,
      attendanceLimit: 20,
      attendanceTotalPages: 0,
      searchHrAttendance: null,
      HrAttendanceSearchMode: false,
      searchAttendanceModel: "",
      // End Empolyee attendance
    };
  },
  methods: {
    logger() {
      this.employee.departments = [];
      this.HrDepartments.forEach((department) => {
        if (department.checked) {
          this.employee.departments.push({
            hr_department_id: department.id,
            hr_job_title_id: this.employee?.job_title_id,
            salary_percent: this.employee?.salary_percent,
          });
        }
      });
      this.http.post("hr-employees", this.employee).then(() => {
        this.get(this.page);
      });
      console.log(this.employee);
    },
    selectAll() {
      this.allSelected = true;
      this.HrDepartments.forEach((department) => (department.checked = true));
    },
    unSelectAll() {
      this.allSelected = false;
      this.HrDepartments.forEach((department) => (department.checked = false));
    },
    addHrEmployees() {
      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "Name" },
          { model: "birthdate", type: "date", label: "Birthdate" },
          {
            model: "hr_department_id",
            type: "select",
            options: (() => {
              return this.HrDepartments.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Department",
          },
          {
            model: "hr_job_title_id",
            type: "select",
            options: (() => {
              return this.HrJobTitles.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Job Title",
          },
          { model: "basic_salary", type: "number", label: "Basic Salary" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("hr-employees", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrEmployees(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "Name",
            value: app?.hr_employee?.name,
          },
          {
            model: "birthdate",
            type: "text",
            label: "Birthdate",
            value: app?.hr_employee?.birthdate,
          },
          {
            model: "hr_department_id",
            type: "select",
            options: (() => {
              return this.HrDepartments.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Department",
          },
          {
            model: "hr_job_title_id",
            type: "select",
            options: (() => {
              return this.HrJobTitles.map((data) => {
                return { value: data?.id, label: data.name };
              });
            })(),
            label: "Job Title",
          },
          {
            model: "basic_salary",
            type: "text",
            label: "Basic Dalary",
            value: app?.hr_employee?.basic_salary,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              let employee = {
                name: obj.name,
                birthdate: obj.birthdate,
                basic_salary: obj.basic_salary,
              };
              let hrEmployeeDepartment = {
                hr_department_id: obj.hr_department_id,
                hr_job_title_id: obj.hr_job_title_id,
              };
              this.http
                .put("hr-employees", app?.hr_employee?.id, {
                  employee,
                  hrEmployeeDepartment,
                })
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrEmployeesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-employees/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployees = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrEmployeesSearchMode = false;
      this.get(this.page);
    },

    getHrEmployees() {
      this.http.get("hr-employees").then((res) => {
        this.HrEmployees = res.data;
      });
    },
    getHrEmployeesAndDepartments() {
      this.http.get("hr-employees/hr-jobs-departments").then((resp) => {
        this.HrDepartments = resp.hrDepartments;
        this.HrDepartments.forEach((department) => {
          department.checked = false;
        });
        this.HrJobTitles = resp.hrJobTitles;
        console.log({ dep: this.HrDepartments });
      });
    },
    deleteHrEmployees(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-employees", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("hr-employees/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployees = res.data;
        });
    },

    // Start Employee attendance methods
    checkIn() {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "hr_employee_id",
            type: "select",
            options: (() => {
              return this.HrEmployees.map((data) => {
                return {
                  value: data?.hr_employee?.id,
                  label: data?.hr_employee?.name,
                };
              });
            })(),
            label: "Employee",
          },
          { model: "date", type: "date", label: "date" },
          { model: "check_in", type: "time", label: "Check In Time" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.check_in = `${obj.date} ${obj.check_in}:00`;
              this.http.post("hr-attendance", obj).then(() => {
                this.getAttendance(this.attendancePage, false);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    checkOut(app) {
      var data = {
        title: "popups.add",
        inputs: [
          { model: "date", type: "date", label: "date" },
          { model: "check_out", type: "time", label: "Check Out Time" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.check_out = `${obj.date} ${obj.check_out}:00`;
              this.http.put("hr-attendance", app.id, obj).then(() => {
                this.getAttendance(this.attendancePage, false);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrAttendance(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "check_in",
            type: "time",
            label: "check_in",
            value: app.check_in,
          },
          {
            model: "check_out",
            type: "time",
            label: "check_out",
            value: app.check_out,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app?.hr_employee?.id;
              this.http.put("hr-attendance", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteHrAttendance(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("hr-attendance", app.id).then(() => {
            this.getAttendance(this.attendancePage, false);
          });
        }
      });
    },
    searchAttendance() {
      this.HrAttendanceSearchMode = true;
      this.attendanceTotalPages = 0;
      this.http
        .post("hr-attendance/search", {
          search: this.searchAttendanceModel,
          limit: this.attendanceLimit,
          page: this.attendancePage,
          col: "name",
        })
        .then((res) => {
          this.HrAttendance = res.data;
          this.HrAttendance.forEach((attend) => {
            if (attend.check_in) {
              attend.check_in = this.formatedTimestamp(attend?.check_in);
            }
            if (attend.check_out) {
              attend.check_out = this.formatedTimestamp(attend?.check_out);
            }
          });
        });
    },
    cancelAttendanceSearchMode() {
      this.searchAttendanceModel = "";
      this.HrAttendanceSearchMode = false;
      this.getAttendance(this.attendancePage, false);
    },
    formatedTimestamp(checkTime) {
      const d = new Date(checkTime);
      const date = d.toISOString().split("T")[0];
      const time = d.toTimeString().split(" ")[0];
      return `${date} ${time}`;
    },
    getAttendance(page, tabClick) {
      if (this.HrAttendance.length > 0 && tabClick) return;
      this.http
        .post("hr-attendance/pagination", {
          limit: this.attendanceLimit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.attendanceTotalPages = Math.ceil(res.tot / this.limit);
          this.HrAttendance = res.data;
          this.HrAttendance.forEach((attend) => {
            if (attend.check_in) {
              attend.check_in = this.formatedTimestamp(attend?.check_in);
            }
            if (attend.check_out) {
              attend.check_out = this.formatedTimestamp(attend?.check_out);
            }
          });
        });
    },
    // End Employee attendance methods
  },
  created() {
    this.get(1);
    this.getHrEmployeesAndDepartments();
  },
};
</script>

<template>

    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.hr_personal_records')"
    />

    <!-- start tabs block -->
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start employees tab -->
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employee_kpi_records.employees") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="HrEmployeesSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              data-bs-target="#addModal"
              data-bs-toggle="modal"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_employees.id") }}</th>
              <th scope="col">{{ $t("hr_employees.name") }}</th>
              <th scope="col">{{ $t("hr_employees.birthdate") }}</th>
              <th scope="col">{{ $t("hr_employees.basic_salary") }}</th>
              <th scope="col">{{ $t("hr_employees.job_title") }}</th>
              <!-- <th scope="col">{{ $t("hr_employees.department") }}</th> -->
              <th scope="col">{{ $t("hr_employees.created") }}</th>
              <th scope="col">{{ $t("hr_employees.updated") }}</th>
              <th scope="col">{{ $t("hr_employees.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in HrEmployees" :key="app" class="text-center">
              <td>{{ app?.hr_employee?.id }}</td>
              <td>{{ app?.hr_employee?.name }}</td>
              <td>{{ app?.hr_employee?.birthdate }}</td>
              <td>
                {{
                  app?.hr_employee?.basic_salary
                    ? app?.hr_employee?.basic_salary
                    : "-"
                }}
              </td>
              <td>{{ app?.hr_job_title?.name }}</td>
              <!-- <td>{{ app?.hr_department?.name }}</td> -->
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  class="btn btn-primary mx-1"
                  @click="editHrEmployees(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrEmployees(app)"
                  >{{ $t("popups.delete") }}</a
                >
                <router-link
                  :to="{
                    name: 'hr_personal_records_empolyee_profile',
                    params: {
                      name: app?.name,
                      id: app?.hr_employee?.id,
                    },
                  }"
                >
                  <button
                    class="btn btn-info mx-1"
                    role="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="app.name + ' ' + $t('hr_employees.profile')"
                  >
                    <i class="bx bxs-detail"></i>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!--Start Add Modal-->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <form @submit.prevent="logger()">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ $t("popups.add") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="margin: 0"
                  ></button>
                </div>

                <div class="modal-body">
                  <label for="name">{{ $t("popups.name") }}</label>
                  <input
                    v-model="employee.name"
                    id="name"
                    required
                    class="form-control"
                    type="text"
                    :placeholder="$t('popups.name')"
                  />
                  <br />
                  <label for="name">{{ $t("hr_employees.birthdate") }}</label>
                  <input
                    v-model="employee.birthdate"
                    id="name"
                    required
                    class="form-control"
                    type="date"
                  />
                  <br />
                  <label for="name">{{
                    $t("hr_employees.basic_salary")
                  }}</label>
                  <input
                    v-model="employee.basic_salary"
                    id="name"
                    required
                    class="form-control"
                    type="number"
                    :placeholder="$t('hr_employees.basic_salary')"
                  />

                  <br />
                  <label for="user">{{ $t("hr_employees.job_title") }}</label>
                  <select v-model="employee.job_title_id" class="form-select">
                    <option
                      v-for="job in HrJobTitles"
                      :key="job"
                      :value="job.id"
                    >
                      {{ job.name }}
                    </option>
                  </select>
                  <br />
                  <div class="form-groub">
                    <label for="name">{{
                      $t("hr_holidays.departments")
                    }}</label>
                    <div
                      class="form-check form-check-inline"
                      style="
                        border-bottom: 1px solid #ccc;
                        margin: 5px;
                        width: 100%;
                        background: #fafafa;
                      "
                    >
                      <label v-if="!allSelected" for="name">select all</label>

                      <input
                        v-if="!allSelected"
                        type="checkbox"
                        class="form-check-input"
                        @change="selectAll($event)"
                        name=""
                        id=""
                      />

                      <label for="name" v-if="allSelected">un select all</label>

                      <input
                        v-if="allSelected"
                        type="checkbox"
                        checked
                        class="form-check-input"
                        @change="unSelectAll($event)"
                        name=""
                        id=""
                      />
                    </div>
                    <div
                      class="form-check"
                      v-for="department in HrDepartments"
                      :key="department"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="department.checked"
                        name=""
                        id=""
                      />
                      <label for="name">{{ department.name }}</label>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--End Add Modal-->

        <div v-if="tot_pages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- end employees tab -->

      <!-- start attendance tab -->
      <b-tab @click="getAttendance(attendancePage, true)">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employee_kpi_records.attendance") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="searchAttendance()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchAttendanceModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="HrAttendanceSearchMode"
              @click="cancelAttendanceSearchMode()"
            ></button>
            <div class="d-flex">
              <!-- <button
                type="button"
                @click="checkOut()"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-log-out float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button> -->
              <button
                type="button"
                @click="checkIn()"
                class="btn btn-light float-end mb-4"
              >
                <span class="bx bxs-log-in float-end fa-2x text-success"></span>
              </button>
            </div>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_attendance.id") }}</th>
              <th scope="col">{{ $t("hr_attendance.hr_employee_id") }}</th>
              <th scope="col">{{ $t("hr_attendance.check_in") }}</th>
              <th scope="col">{{ $t("hr_attendance.check_out") }}</th>
              <th scope="col">{{ $t("hr_attendance.created") }}</th>
              <th scope="col">{{ $t("hr_attendance.updated") }}</th>
              <th scope="col">{{ $t("hr_attendance.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in HrAttendance" :key="app" class="text-center">
              <td>{{ app?.id }}</td>
              <td>{{ app?.hr_employee?.name }}</td>
              <td>{{ app?.check_in ? app?.check_in : "-" }}</td>
              <td>{{ app?.check_out ? app?.check_out : "-" }}</td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex justify-content-between">
                <button
                  class="btn btn-light mx-1"
                  @click="checkOut(app)"
                  href="javascript: void(0);"
                  :disabled="app?.check_out"
                >
                  <i class="bx bxs-log-out text-primary fa-2x"></i>
                </button>
                <!-- <a
                  class="btn btn-primary mx-1"
                  @click="editHrAttendance(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                > -->
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrAttendance(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- end attendance tab -->
    </b-tabs>
    <!-- end tabs block -->

</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
